exports.components = {
  "component---node-modules-newrelic-gatsby-theme-newrelic-src-pages-404-js": () => import("./../../../node_modules/@newrelic/gatsby-theme-newrelic/src/pages/404.js" /* webpackChunkName: "component---node-modules-newrelic-gatsby-theme-newrelic-src-pages-404-js" */),
  "component---src-pages-attribute-dictionary-js": () => import("./../../../src/pages/attribute-dictionary.js" /* webpackChunkName: "component---src-pages-attribute-dictionary-js" */),
  "component---src-pages-eol-js": () => import("./../../../src/pages/eol.js" /* webpackChunkName: "component---src-pages-eol-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-install-config-agent-name-js": () => import("./../../../src/pages/install/{installConfig.agentName}.js" /* webpackChunkName: "component---src-pages-install-install-config-agent-name-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-whats-new-js": () => import("./../../../src/pages/whats-new.js" /* webpackChunkName: "component---src-pages-whats-new-js" */),
  "component---src-templates-doc-page-js": () => import("./../../../src/templates/docPage.js" /* webpackChunkName: "component---src-templates-doc-page-js" */),
  "component---src-templates-eol-announcement-js": () => import("./../../../src/templates/eolAnnouncement.js" /* webpackChunkName: "component---src-templates-eol-announcement-js" */),
  "component---src-templates-release-note-js": () => import("./../../../src/templates/releaseNote.js" /* webpackChunkName: "component---src-templates-release-note-js" */),
  "component---src-templates-release-note-landing-page-js": () => import("./../../../src/templates/releaseNoteLandingPage.js" /* webpackChunkName: "component---src-templates-release-note-landing-page-js" */),
  "component---src-templates-whats-new-js": () => import("./../../../src/templates/whatsNew.js" /* webpackChunkName: "component---src-templates-whats-new-js" */)
}

